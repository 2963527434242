.header-bg-image {
  background-image: url("/public/images/iyt-header.png");
}

.header-bg-contact {
  background-image: url("/public/images/contactus.png");
}

.header-bg-aboutus {
  background-image: url("/public/images/aboutus-header.jpg");
  background-blend-mode: darken;
  height: 36rem;
}

.header-bg-blogs {
  background-image: url('/public/images/teen-girl-smiling-in-a-garden.png');
  height: 36rem;
}

.header-bg-events {
  background-image: url("/public/images/events-head.jpg");
  background-blend-mode: darken;
  height: 32rem;
}

.header-bg-donate {
  background-image: url("/public/images/donate-header.jpg");
  height: 28rem;
}

.search-width {
  max-width: 38rem;
  width: 100%;
  min-width: 16rem;

}

/* .team-bg-img-1 {
  background-image: url("/public/images/aunty-kelechi.jpg");
}

.team-bg-img-2 {
  background-image: url("/public/images/aunty-kelechi.jpg");
}

.team-bg-img-3 {
  background-image: url("/public/images/aunty-kelechi.jpg");
}

.team-bg-img-4 {
  background-image: url("/public/images/aunty-kelechi.jpg");
}

.team-bg-img-5 {
  background-image: url("/public/images/aunty-kelechi.jpg");
}

.team-bg-img-6 {
  background-image: url("/public/images/aunty-kelechi.jpg");
}

.team-bg-img-7 {
  background-image: url("/public/images/aunty-kelechi.jpg");
}

.team-bg-img-8 {
  background-image: url("/public/images/aunty-kelechi.jpg");
} */
